import {
  BinnacleComment,
  Budget,
  BudgetCondition,
  BudgetConditionPayload,
  BudgetDetailGrouped,
  BudgetDetailPayload,
  BudgetInvoice,
  BudgetInvoiceStatusUpdate,
  BudgetStatusUpdate,
  BudgetToInvoice,
  GeoBudgetCondition,
  DocumentKind,
  InternalOperation,
  InternalOperationKind,
  InvoiceCommercialControl,
  KanbanCol,
  Lead,
  LeadAttachment,
  LeadDetail,
  LeadDetailGrouped,
  LeadDetailPayload,
  LeadRequestV2,
  LeadResume,
  LeadStatus,
  LeadStatusLog,
  Movement,
  PaymentsCommercialControl,
  PurchaseOrder,
  PurchaseOrderDetailGrouped,
  PurchaseOrderInvoice,
  Recommendation,
  Resume,
  ResumeRequest,
  SummaryCommercialControl,
  SummaryCommercialControlModule,
  SupplierSearch,
  TechnicalIssue,
  ValidationError,
  CreditNote,
  CreditNotesSummary,
  CreditNoteDetail,
  LeadItem,
  LeadItemPeriod,
} from "@/lib/dto/leads";
import { GoPage, ListPageResults } from "@/lib/dto/requests";
import { url } from "inspector";
import _, { List } from "lodash";
import { API_HOST } from "../client/config";
import {
  CreateLeaseDetail,
  ImagesPayload,
  ProductImage,
  SupplierProduct,
} from "../dto/inventory";
import { firstLetterCapitalize } from "../utils";
import { PAGE_SIZE, PAGE_SIZE_LEAD } from "./config";
import { inventoryApi, parseAttributes } from "./inventory";
import { SimpleOrganization } from "../dto/organizations";

export interface RecommendationGoPage {
  category_id: number;
  start_date: string;
  days: number;
  geo_unit_id: number;
}

interface BudgetPayload {
  leadId: number;
  send_budget_mail: boolean;
  kind: string;
}

interface BudgetUpdatePayload {
  terms_and_conditions?: string;
  payment_info?: string;
  status?: string;
  target_supplier_id?: number;
  discount_value?: number;
  additional_tax?: number;
  tax_retention_value?: number;
}

interface duplicatePayload {
  data: Lead;
}

interface BinnacleRequest {
  goPage: GoPage | null;
  kind: string;
  id: number;
}

interface CreateLeadToOrderPayload {
  lead_id: string;
  address_street?: string;
  address_street_number?: string;
  address_reference?: string;
  geo_unit_id?: number;
  details: CreateLeaseDetail[];
}

export const leadsApi = inventoryApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLeadStatuses: builder.query<LeadStatus[], undefined>({
      query: () => "/lead-statuses",
    }),
    getBudgets: builder.query<ListPageResults<Budget>, GoPage | null>({
      providesTags: ["Budgets"],
      query: (goPage) => {
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          console.log(goPage);
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);

          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `/budgets?${params.toString()}`;
      },
    }),

    updateStatusBudget: builder.mutation<void, BudgetStatusUpdate>({
      invalidatesTags: ["Budgets"],
      query: (budget) => ({
        url: `leads/budgets/${budget.id}/status`,
        body: {
          status: budget.status,
        },
        method: "POST",
      }),
    }),

    getBinnacle: builder.query<
      ListPageResults<BinnacleComment>,
      BinnacleRequest
    >({
      providesTags: ["Binnacle"],
      query: ({ kind, id, goPage }) => {
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v101/${kind}/${id}/comments`;
      },
    }),
    postBinnacleComment: builder.mutation<BinnacleComment, BinnacleComment>({
      invalidatesTags: ["Binnacle"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/${payload.comment_kind}/${payload.id}/comments`,
        body: { text: payload.text },
        method: "POST",
      }),
    }),
    updateBinnacleComment: builder.mutation<void, { id: number; text: string }>(
      {
        invalidatesTags: ["Binnacle"],
        query: (payload) => ({
          url: `${API_HOST}/suppliers/v101/comments/${payload.id}`,
          body: { text: payload.text },
          method: "PATCH",
        }),
      }
    ),
    getLeads: builder.query<ListPageResults<Lead>, GoPage | null>({
      providesTags: ["Lead", "LeadStatus"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.page_size)
            params.append("page_size", goPage.page_size.toString());
          if (goPage.page_number)
            params.append("page_number", goPage.page_number.toString());
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.is_archived) {
            params.append("is_archived", `${goPage.is_archived}`);
          }
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "categories") {
                goPage.filters.categories?.forEach((id: number) =>
                  params.append("category_ids", `${id}`)
                );
              }
              if (key === "tags") {
                goPage.filters.tags?.forEach((id: number) =>
                  params.append("tags", `${id}`)
                );
              } else if (
                key === "new_status" &&
                _.isArray(goPage.filters.new_status)
              ) {
                goPage.filters.new_status?.forEach((value: string) =>
                  params.append("new_status", `${value}`)
                );
              } else if (key === "kind") {
                goPage.filters.kind?.forEach((value: string) =>
                  params.append("kind", `${value}`)
                );
              } else {
                params.append(key, goPage.filters[key]);
              }
            });
          }
        }
        return `leads?${params.toString()}`;
      },
    }),
    getLeadsFilteredTemporal: builder.query<
      ListPageResults<Lead>,
      GoPage | null
    >({
      providesTags: ["Lead", "LeadStatus"],
      query: (goPage) => {
        const params = new URLSearchParams();

        params.append(
          "page_size",
          goPage?.page_size
            ? goPage.page_size.toString()
            : PAGE_SIZE_LEAD.toString()
        );

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.is_archived) {
            params.append("is_archived", `${goPage.is_archived}`);
          }
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "categories") {
                goPage.filters.categories?.forEach((id: number) =>
                  params.append("category_ids", `${id}`)
                );
              }
              if (key === "tags") {
                goPage.filters.tags?.forEach((id: number) =>
                  params.append("tags", `${id}`)
                );
              } else if (key === "kind") {
                goPage.filters.kind?.forEach((value: string) =>
                  params.append("kind", `${value}`)
                );
              } else {
                params.append(key, goPage.filters[key]);
              }
            });
          }
        }
        return `${API_HOST}/suppliers/v102/leads/organizations?${params.toString()}`;
      },
    }),
    getLeadsByStatusForKanban: builder.query<any, GoPage | null>({
      providesTags: ["Lead", "LeadStatus"],
      query: (goPage) => {
        const params = new URLSearchParams();
        params.append(
          "page_size",
          goPage?.page_size ? goPage.page_size.toString() : PAGE_SIZE.toString()
        );

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "categories") {
                goPage.filters.categories?.forEach((id: number) =>
                  params.append("category_ids", `${id}`)
                );
              } else if (key === "kind") {
                goPage.filters.kind?.forEach((value: string) =>
                  params.append("kind", `${value}`)
                );
              } else {
                params.append(key, goPage.filters[key]);
              }
            });
          }
        }
        return `leads-by-status?${params.toString()}`;
      },
      transformResponse: (response: any) => {
        let allTasks: LeadRequestV2[] = [];
        let allCols: KanbanCol[] = [];
        let columnOrder = response.map((lane: KanbanCol) => `col-${lane.id}`);

        response.forEach((lane: KanbanCol) => {
          allTasks = [...allTasks, ...lane.leads];

          allCols.push({
            ...lane,
            taskIds: lane.leads.map((lead) => `card-${lead.id}`),
          });
        });

        const tasks = allTasks.reduce(
          (a, v) => ({ ...a, [`card-${v.id}`]: v }),
          {}
        );
        const columns = allCols.reduce(
          (a, v) => ({ ...a, [`col-${v.id}`]: v }),
          {}
        );

        const transformed = {
          tasks,
          columns,
          columnOrder,
        };

        return transformed;
      },
    }),

    getLead: builder.query<Lead, string | undefined>({
      keepUnusedDataFor: 0,
      providesTags: ["Lead"],
      query: (id) => `leads/${id}`,
      transformResponse: (response: Lead): Lead => {
        const transformed = {
          ...response,
          details: response.details?.map((detail: any) => ({
            ...(detail as LeadDetail),
            category: {
              ...detail.category,
              attributesList: parseAttributes(
                detail?.category?.attributes || {}
              ),
            },
          })),
        };
        return transformed;
      },
    }),

    getLeadV2: builder.query<Lead, string | undefined>({
      keepUnusedDataFor: 0,
      providesTags: ["Lead"],
      query: (id) => `${API_HOST}/suppliers/v102/leads/${id}`,
      transformResponse: (response: Lead): Lead => {
        const transformed = {
          ...response,
          details: response.details?.map((detail: any) => ({
            ...(detail as LeadDetail),
            category: {
              ...detail.category,
              attributesList: parseAttributes(
                detail?.category?.attributes || {}
              ),
            },
          })),
        };
        return transformed;
      },
    }),

    getRecommendations: builder.query<
      Recommendation[],
      RecommendationGoPage | null
    >({
      providesTags: ["Order"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          params.append("category_id", goPage.category_id.toString());
          params.append("start_date", goPage.start_date.toString());
          params.append("days", goPage.days.toString());
          params.append("geo_unit_id", goPage.geo_unit_id.toString());
        }
        return `/category-recommendations?${params.toString()}`;
      },
    }),

    saveLead: builder.mutation<Lead, LeadRequestV2>({
      invalidatesTags: ["Lead"],
      query: (lead) => ({
        url: "leads",
        body: lead,
        method: "POST",
      }),
    }),

    createLeadToOrder: builder.mutation<any, CreateLeadToOrderPayload>({
      invalidatesTags: ["Lead", "Order"],
      query: (lead) => ({
        url: `${API_HOST}/suppliers/v100/lead-to-order`,
        body: lead,
        method: "POST",
      }),
    }),

    duplicateLead: builder.mutation<Lead, number>({
      invalidatesTags: ["Lead"],
      query: (lead) => ({
        url: `leads/${lead}/duplicate`,
        method: "POST",
      }),
    }),

    deleteLead: builder.mutation<undefined, number>({
      invalidatesTags: ["Lead"],
      query: (lead) => ({
        url: `leads/${lead}`,
        body: lead,
        method: "DELETE",
      }),
    }),

    updateLead: builder.mutation<Lead, LeadRequestV2>({
      invalidatesTags: ["Lead", "LeadKanban", "Schedule", "LeadStatusLogs"],
      query: (lead) => ({
        url: `leads/${lead.id}`,
        body: lead,
        method: "PUT",
      }),
    }),

    updateStatusLead: builder.mutation({
      invalidatesTags: ["LeadStatus"],
      query: (payload) => ({
        url: `leads/${payload.id}/status/${payload.statusId}`,
        body: payload,
        method: "PATCH",
      }),
    }),

    createBudget: builder.mutation<any, BudgetPayload>({
      invalidatesTags: ["Budgets", "LeadResume", "LeadStatusLogs"],
      query: ({ leadId, send_budget_mail, kind }) => ({
        url: `leads/${leadId}/budgets`,
        method: "POST",
        body: { send_budget_mail, kind },
      }),
    }),

    calculatePrice: builder.mutation<Resume, ResumeRequest>({
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/calculate-price`,
        method: "POST",
        body: payload,
      }),
    }),
    getBudgetV2: builder.query<Budget, string | undefined>({
      keepUnusedDataFor: 0,
      providesTags: ["Budgets"],
      query: (id) => `${API_HOST}/suppliers/v101/budgets/${id}`,
    }),
    createBudgetV2: builder.mutation<any, any>({
      invalidatesTags: ["Budgets"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/budgets`,
        method: "POST",
        body: payload,
      }),
    }),
    sendBudgetV2: builder.mutation<
      any,
      { id: number | string; emails?: string[] }
    >({
      invalidatesTags: ["Budgets"],
      query: ({ id, emails }) => {
        return {
          url: `${API_HOST}/suppliers/v101/budgets/${id}/send`,
          method: "POST",
          body: emails ? { emails: emails } : null,
        };
      },
    }),
    editBudgetV2: builder.mutation<any, any>({
      invalidatesTags: ["Budgets"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/budgets/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deleteBudgetV2: builder.mutation<any, any>({
      invalidatesTags: ["Budgets"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v101/budgets/${id}`,
        method: "DELETE",
      }),
    }),

    getPurchaseOrderListV2: builder.query<
      ListPageResults<PurchaseOrder>,
      GoPage | null
    >({
      keepUnusedDataFor: 0,
      providesTags: ["PurchaseOrders"],
      query: (goPage) => {
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v101/purchase-orders?${params.toString()}`;
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          results: response.results.map((purchaseOrder: PurchaseOrder) => {
            return {
              ...purchaseOrder,
              target_supplier: {
                ...purchaseOrder.target_supplier,
                name: firstLetterCapitalize(
                  purchaseOrder.target_supplier?.name
                ),
              },
            };
          }),
        };
      },
    }),
    getPurchaseOrderV2: builder.query<PurchaseOrder, string | undefined>({
      keepUnusedDataFor: 0,
      providesTags: ["PurchaseOrders"],
      query: (id) => `${API_HOST}/suppliers/v101/purchase-orders/${id}`,
    }),
    createPurchaseOrderV2: builder.mutation<any, any>({
      invalidatesTags: ["PurchaseOrders", "LeadDetails"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/purchase-orders`,
        method: "POST",
        body: payload,
      }),
    }),
    sendPurchaseOrderV2: builder.mutation<
      any,
      { id: string | number; emails?: string[] }
    >({
      invalidatesTags: ["PurchaseOrders"],
      query: ({ id, emails }) => {
        return {
          url: `${API_HOST}/suppliers/v101/purchase-orders/${id}/send`,
          method: "POST",
          body: emails ? { emails: emails } : null,
        };
      },
    }),
    updateStatusPurchaseOrder: builder.mutation<void, BudgetStatusUpdate>({
      invalidatesTags: ["PurchaseOrders", "LeadDetails"],
      query: (purchaseOrder) => ({
        url: `${API_HOST}/suppliers/v101/purchase-orders/${purchaseOrder.id}/status`,
        body: {
          status: purchaseOrder.status,
        },
        method: "PUT",
      }),
    }),
    editPruchaseOrderV2: builder.mutation<any, any>({
      invalidatesTags: ["PurchaseOrders"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/purchase-orders/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deletePruchaseOrderV2: builder.mutation<any, any>({
      invalidatesTags: ["PurchaseOrders"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v101/purchase-orders/${id}`,
        method: "DELETE",
      }),
    }),

    assignResponsibles: builder.mutation({
      invalidatesTags: ["LeadStatus"],
      query: ({ id, assigned }) => ({
        url: `leads/${id}/assigned`,
        body: {
          assigned: assigned,
        },
        method: "PUT",
      }),
    }),

    createBudgetToInvoice: builder.mutation<
      any,
      { id: number; data: FormData }
    >({
      invalidatesTags: ["BudgetInvoices"],
      query: ({ id, data }) => ({
        url: `${API_HOST}/suppliers/v100/budgets/${id}/convert-to-invoice`,
        body: data,
        method: "POST",
      }),
    }),

    getBudgetInvoices: builder.query<
      ListPageResults<BudgetInvoice>,
      GoPage | null
    >({
      providesTags: ["BudgetInvoices"],
      query: (goPage) => {
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else if (key === "leadId" && goPage.filters[key])
                params.append("lead_id", goPage.filters[key]);
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `/budgets/invoices?${params.toString()}`;
      },
    }),

    deleteBudgetInvoice: builder.mutation<void, number>({
      invalidatesTags: ["BudgetInvoices"],
      query: (id) => ({
        url: `/budgets/invoices/${id}`,
        method: "DELETE",
      }),
    }),

    updateBudgetInvoiceStatus: builder.mutation<any, BudgetInvoiceStatusUpdate>(
      {
        invalidatesTags: ["BudgetInvoices"],
        query: (payload) => ({
          url: `/budgets/invoices/${payload.id}/status`,
          method: "PUT",
          body: payload,
        }),
      }
    ),

    updateBudgetInvoice: builder.mutation<any, { id: number; data: FormData }>({
      invalidatesTags: ["BudgetInvoices"],
      query: ({ id, data }) => ({
        url: `/budgets/invoices/${id}`,
        method: "PUT",
        body: data,
      }),
    }),

    getPurchaseOrderInvoices: builder.query<
      ListPageResults<PurchaseOrderInvoice>,
      GoPage | null
    >({
      providesTags: ["PurchaseOrderInvoices"],
      query: (goPage) => {
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          console.log(goPage);
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else if (key === "leadId" && goPage.filters[key])
                params.append("lead_id", goPage.filters[key]);
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v101/purchase-order/invoices?${params.toString()}`;
      },
    }),

    createPurchaseOrderToInvoice: builder.mutation<
      any,
      { id: number; data: FormData }
    >({
      invalidatesTags: ["PurchaseOrderInvoices"],
      query: ({ id, data }) => ({
        url: `${API_HOST}/suppliers/v101/purchase-order/${id}/convert-to-invoice`,
        body: data,
        method: "POST",
      }),
    }),

    deletePurchaseOrderInvoice: builder.mutation<void, number>({
      invalidatesTags: ["PurchaseOrderInvoices"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v101/purchase-order/invoices/${id}`,
        method: "DELETE",
      }),
    }),

    updatePurchaseOrderInvoiceStatus: builder.mutation<
      any,
      BudgetInvoiceStatusUpdate
    >({
      invalidatesTags: ["PurchaseOrderInvoices"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/purchase-order/invoices/${payload.id}/status`,
        method: "PUT",
        body: payload,
      }),
    }),

    updatePurchaseOrderInvoice: builder.mutation<
      any,
      { id: number; data: FormData }
    >({
      invalidatesTags: ["PurchaseOrderInvoices"],
      query: ({ id, data }) => ({
        url: `${API_HOST}/suppliers/v101/purchase-order/invoices/${id}`,
        method: "PUT",
        body: data,
      }),
    }),

    validateLeadRequiredData: builder.mutation<
      ValidationError[] | null,
      number
    >({
      query: (id) => ({
        url: `${API_HOST}/suppliers/v100/leads/${id}/validate`,
        method: "POST",
      }),
    }),

    createLeadAttachment: builder.mutation<
      void,
      { id: string; data: FormData }
    >({
      invalidatesTags: ["LeadAttachments"],
      query: ({ id, data }) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v100/leads/${id}/attachments`,
          body: data,
        };
      },
    }),

    getLeadAttachments: builder.query<
      LeadAttachment[],
      { id: number | string; kind?: DocumentKind }
    >({
      providesTags: ["LeadAttachments"],
      query: ({ id, kind }) => {
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE.toString());

        if (kind) {
          params.append("kind", kind);
        } else {
          params.append("kind", "other");
        }

        return `${API_HOST}/suppliers/v100/leads/${id}/attachments?${params.toString()}`;
      },
    }),

    getBudgetsConditions: builder.query<
      ListPageResults<BudgetCondition>,
      GoPage
    >({
      providesTags: ["BudgetsConditions"],
      query: (goPage) => {
        const params = new URLSearchParams();

        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/lease-price?${params.toString()}`;
      },
    }),

    getGeoBudgetsConditions: builder.query<GeoBudgetCondition[], GoPage>({
      providesTags: ["GeoBudgetsConditions"],
      query: (goPage) => {
        const params = new URLSearchParams();

        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/geo-lease-prices?${params.toString()}`;
      },
    }),

    createGeoBudgetCondition: builder.mutation<
      GeoBudgetCondition,
      GeoBudgetCondition
    >({
      invalidatesTags: ["GeoBudgetsConditions"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/geo-lease-prices`,
        method: "POST",
        body: payload,
      }),
    }),
    updateGeoBudgetCondition: builder.mutation<
      GeoBudgetCondition,
      { id: number; payload: GeoBudgetCondition }
    >({
      invalidatesTags: ["GeoBudgetsConditions"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/geo-lease-prices/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    deleteGeoBudgetCondition: builder.mutation<void, number>({
      invalidatesTags: ["GeoBudgetsConditions"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/geo-lease-prices/${id}`,
        method: "DELETE",
      }),
    }),

    createBudgetCondition: builder.mutation<
      BudgetCondition,
      BudgetConditionPayload
    >({
      invalidatesTags: ["BudgetsConditions"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/lease-price`,
        method: "POST",
        body: payload,
      }),
    }),

    updateBudgetCondition: builder.mutation<
      BudgetCondition,
      { id: number; payload: BudgetConditionPayload }
    >({
      invalidatesTags: ["BudgetsConditions"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/lease-price/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    deleteBudgetCondition: builder.mutation<void, number>({
      invalidatesTags: ["BudgetsConditions"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/lease-price/${id}`,
        method: "DELETE",
      }),
    }),

    getSupplierSearchForLeadDetail: builder.query<SupplierSearch[], number>({
      providesTags: ["SearchForSupplierLead"],
      query: (id) => {
        return `${API_HOST}/suppliers/v102/search-supplier-for-lead-detail/${id}`;
      },
    }),

    createSupplierSearchForLeadDetail: builder.mutation<
      BudgetCondition,
      number
    >({
      invalidatesTags: ["Lead", "SearchForSupplierLead"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/search-supplier-for-lead-detail/${id}`,
        method: "POST",
      }),
    }),

    getLeadResume: builder.query<LeadResume, number | string>({
      providesTags: ["LeadResume"],
      query: (id) => `${API_HOST}/suppliers/v102/leads/${id}/summary`,
    }),
    // TODO: Eliminar lead details grouped ya que es un vestigio
    getLeadDetailsGrouped: builder.query<
      LeadDetailGrouped[],
      { id: number | string; order_by?: string[] }
    >({
      providesTags: ["LeadDetails"],
      query: ({ id, order_by }) => {
        const params = new URLSearchParams();
        if (order_by) {
          order_by.forEach((value) => {
            params.append("order_by", value);
          });
        }
        return `${API_HOST}/suppliers/v102/leads/${id}/details?${params.toString()}`;
      },
    }),
    createLeadDetail: builder.mutation<
      LeadDetail,
      { id: number | string; payload: LeadDetailPayload }
    >({
      invalidatesTags: ["LeadDetails", "LeadResume", "LeadStatusLogs"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/leads/${id}/details`,
        method: "POST",
        body: payload,
      }),
    }),

    updateLeadDetail: builder.mutation<
      LeadDetail,
      { id: number | string; payload: LeadDetailPayload }
    >({
      invalidatesTags: [
        "LeadDetails",
        "PurchaseOrdersDetails",
        "LeadResume",
        "LeadStatusLogs",
      ],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/lead-detail/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    deleteLeadDetail: builder.mutation<void, number | string>({
      invalidatesTags: ["LeadDetails", "LeadResume", "LeadStatusLogs"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/lead-detail/${id}`,
        method: "DELETE",
      }),
    }),
    getLeadItems: builder.query<LeadItem[], { id: string | number }>({
      providesTags: ["LeadDetails"],
      query: ({ id }) => {
        return `${API_HOST}/suppliers/v102/leads/${id}/lead-items`;
      },
    }),
    createLeadItem: builder.mutation<
      LeadItem,
      { lead_id: string; category_id: number }
    >({
      invalidatesTags: [
        "LeadDetails",
        "PurchaseOrdersDetails",
        "BudgetsDetails",
        "LeadResume",
        "LeadStatusLogs",
      ],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/leads/${payload.lead_id}/lead-items`,
        method: "POST",
        body: payload,
      }),
    }),
    updateLeadItem: builder.mutation<
      LeadItem,
      { id: number; category_id: number | undefined }
    >({
      invalidatesTags: [
        "LeadDetails",
        "PurchaseOrdersDetails",
        "BudgetsDetails",
        "LeadResume",
        "LeadStatusLogs",
      ],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/lead-items/${payload.id}`,
        method: "PATCH",
        body: payload,
      }),
    }),
    deleteLeadItem: builder.mutation<LeadItem, number>({
      invalidatesTags: [
        "LeadDetails",
        "PurchaseOrdersDetails",
        "BudgetsDetails",
        "LeadResume",
        "LeadStatusLogs",
      ],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/lead-items/${id}`,
        method: "DELETE",
      }),
    }),
    createLeadItemPeriod: builder.mutation<LeadItemPeriod, LeadItemPeriod>({
      invalidatesTags: [
        "LeadDetails",
        "PurchaseOrdersDetails",
        "LeadResume",
        "LeadStatusLogs",
      ],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/lead-items/${payload.item_id}/details`,
        method: "POST",
        body: payload,
      }),
    }),
    updateLeadItemPeriod: builder.mutation<LeadItemPeriod, LeadItemPeriod>({
      invalidatesTags: [
        "LeadDetails",
        "PurchaseOrdersDetails",
        "LeadResume",
        "LeadStatusLogs",
      ],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/lead-items/details/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deleteLeadItemPeriod: builder.mutation<LeadItemPeriod, number>({
      invalidatesTags: [
        "LeadDetails",
        "PurchaseOrdersDetails",
        "LeadResume",
        "LeadStatusLogs",
      ],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/lead-items/details/${id}`,
        method: "DELETE",
      }),
    }),
    getBudgetsByLead: builder.query<
      Budget[],
      { id: number | string; goPage?: GoPage }
    >({
      providesTags: ["Budgets"],
      query: ({ id, goPage }) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.page_size)
            params.append("page_size", goPage.page_size.toString());
          if (goPage.page_number)
            params.append("page_number", goPage.page_number.toString());
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.status) params.append("status", goPage.status);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/leads/${id}/budget?${params.toString()}`;
      },
    }),

    getNonPendingDocs: builder.query<
      Budget[],
      { leadId: number | string; kind: string }
    >({
      providesTags: ["Budgets", "PurchaseOrders"],
      query: ({ leadId, kind }) => ({
        url: `${API_HOST}/suppliers/v102/leads/${leadId}/non-pending/${kind}`,
        method: "GET",
      }),
    }),

    createBudgetByLead: builder.mutation<
      Budget,
      { id: number | string; terms_and_conditions?: string }
    >({
      invalidatesTags: ["Budgets"],
      query: ({ id, terms_and_conditions }) => ({
        url: `${API_HOST}/suppliers/v102/leads/${id}/budget`,
        method: "POST",
        body: {
          terms_and_conditions: terms_and_conditions,
        },
      }),
    }),

    getInternalOperations: builder.query<
      InternalOperation[],
      InternalOperationKind
    >({
      query: (kind) =>
        `${API_HOST}/suppliers/v102/internal-operations?kind=${kind}`,
    }),

    createBudgetDetail: builder.mutation<
      BudgetDetailPayload,
      BudgetDetailPayload
    >({
      invalidatesTags: ["Budgets", "BudgetsDetails"],
      query: (payload) => {
        const transformed = {
          ...payload,
          discount_tax: ((payload.discount_tax ?? 0) * 0.01).toFixed(4),
        };
        return {
          url: `${API_HOST}/suppliers/v102/budgets/${transformed.budget_id}/details`,
          method: "POST",
          body: transformed,
        };
      },
      transformResponse: (response: BudgetDetailPayload) => {
        const transformed = {
          ...response,
          discount_tax: response.discount_tax
            ? Number(((response.discount_tax ?? 0) * 100).toFixed(2))
            : 0,
        };
        return transformed;
      },
    }),

    getBudgetDetails: builder.query<BudgetDetailGrouped[], number | string>({
      providesTags: ["BudgetsDetails"],
      query: (id) => `${API_HOST}/suppliers/v102/budgets/${id}/details`,
      transformResponse: (response: BudgetDetailGrouped[]) => {
        const transformed = response.map((group) => ({
          ...group,
          budget_details: group.budget_details.map((detail) => ({
            ...detail,
            discount_tax: detail.discount_tax
              ? Number(((detail.discount_tax ?? 0) * 100).toFixed(2))
              : 0,
          })),
        }));
        return transformed;
      },
    }),

    updateBudgetDetail: builder.mutation<
      BudgetDetailPayload,
      BudgetDetailPayload
    >({
      invalidatesTags: [
        "Budgets",
        "BudgetsDetails",
        "LeadStatusLogs",
        "LeadResume",
      ],
      query: (payload) => {
        const transformed = {
          ...payload,
          discount_tax: ((payload.discount_tax ?? 0) * 0.01).toFixed(4),
        };
        return {
          url: `${API_HOST}/suppliers/v102/budgets/details/${transformed.id}`,
          method: "PUT",
          body: transformed,
        };
      },
      transformResponse: (response: BudgetDetailPayload) => {
        const transformed = {
          ...response,
          discount_tax: Number(((response.discount_tax ?? 0) * 100).toFixed(2)),
        };
        return transformed;
      },
    }),

    deleteBudgetDetails: builder.mutation<
      void,
      { budgetId: number | string; leadDetailId: number | string }
    >({
      invalidatesTags: ["Budgets", "BudgetsDetails"],
      query: ({ budgetId, leadDetailId }) => ({
        url: `${API_HOST}/suppliers/v102/budgets/${budgetId}/details/${leadDetailId}`,
        method: "DELETE",
      }),
    }),

    deleteBudgetDetail: builder.mutation<void, number | string>({
      invalidatesTags: ["BudgetsDetails", "LeadStatusLogs"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/budgets/details/${id}`,
        method: "DELETE",
      }),
    }),

    deleteBudget: builder.mutation<void, number | string>({
      invalidatesTags: ["Budgets", "LeadResume", "LeadStatusLogs"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/budgets/${id}`,
        method: "DELETE",
      }),
    }),

    updateBudget: builder.mutation<
      Budget,
      { id: number | string; payload: BudgetUpdatePayload }
    >({
      invalidatesTags: [
        "Budgets",
        "LeadStatusLogs",
        "LeadResume",
        "Lead",
        "LeadKanban",
        "LeadMovements",
        "Schedule",
      ],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/budgets/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    getLeadStatusLogs: builder.query<LeadStatusLog[], number | string>({
      providesTags: ["LeadStatusLogs"],
      query: (id) => `${API_HOST}/suppliers/v102/leads/${id}/logs`,
    }),

    getMovementsList: builder.query<Movement[], number | string>({
      query: (id) => `${API_HOST}/suppliers/v102/leads/${id}/movements`,
      providesTags: ["LeadMovements"],
    }),
    getMovement: builder.query<Movement, number>({
      query: (id) => `${API_HOST}/suppliers/v102/movements/${id}`,
      providesTags: ["LeadMovements"],
    }),
    createMovement: builder.mutation<
      Movement,
      { id: number | string; payload: Movement }
    >({
      invalidatesTags: [
        "LeadMovements",
        "LeadStatusLogs",
        "Lead",
        "LeadDetails",
      ],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/leads/${id}/movements`,
        method: "POST",
        body: payload,
      }),
    }),
    editMovement: builder.mutation<Movement, Movement>({
      invalidatesTags: ["LeadMovements", "SupplierProducts", "LeadDetails"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/movements/${payload.id}/`,
        method: "PUT",
        body: payload,
      }),
    }),
    removeMovement: builder.mutation<void, number>({
      invalidatesTags: ["LeadMovements", "LeadStatusLogs", "Lead"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/movements/${id}/`,
        method: "DELETE",
      }),
    }),
    saveMovementImages: builder.mutation<ProductImage[], ImagesPayload>({
      invalidatesTags: ["LeadMovements"],
      query: ({ images, productId }) => ({
        url: `${API_HOST}/suppliers/v102/movements/${productId}/images`,
        body: images,
        method: "POST",
      }),
    }),

    createPurchaseOrderByLead: builder.mutation<
      PurchaseOrder,
      { leadId: number | string; supplierId: number; payment_info?: string }
    >({
      invalidatesTags: ["PurchaseOrders", "LeadDetails"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/leads/${payload.leadId}/purchase-order`,
        method: "POST",
        body: {
          supplier_id: payload.supplierId,
          payment_info: payload.payment_info,
        },
      }),
    }),

    createPurchaseOrderDetail: builder.mutation<
      BudgetDetailPayload,
      BudgetDetailPayload
    >({
      invalidatesTags: (result, error) =>
        error ? [] : ["PurchaseOrders", "PurchaseOrdersDetails"],
      query: (payload) => {
        const transformed = {
          ...payload,
          discount_tax: Number((payload.discount_tax ?? 0) * 0.01).toFixed(4),
        };
        return {
          url: `${API_HOST}/suppliers/v102/purchase-order/${transformed.purchase_order_id}/details`,
          method: "POST",
          body: transformed,
        };
      },
    }),

    getPurchaseOrderDetails: builder.query<
      PurchaseOrderDetailGrouped[],
      number | string
    >({
      providesTags: ["PurchaseOrdersDetails"],
      query: (id) => `${API_HOST}/suppliers/v102/purchase-order/${id}/details`,
      transformResponse: (response: PurchaseOrderDetailGrouped[]) => {
        const transformed = response.map((group) => ({
          ...group,
          purchase_order_details: group.purchase_order_details.map(
            (detail) => ({
              ...detail,
              discount_tax: detail.discount_tax
                ? Number(((detail.discount_tax ?? 0) * 100).toFixed(2))
                : 0,
            })
          ),
        }));
        return transformed;
      },
    }),

    deletePurchaseOrderDetail: builder.mutation<void, number | string>({
      invalidatesTags: ["PurchaseOrders", "PurchaseOrdersDetails"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/purchase-order/details/${id}`,
        method: "DELETE",
      }),
    }),

    deletePurchaseOrder: builder.mutation<void, number>({
      invalidatesTags: ["PurchaseOrders"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v101/purchase-orders/${id}`,
        method: "DELETE",
      }),
    }),

    updatePurchaseOrder: builder.mutation<
      PurchaseOrder,
      { id: number | string; payload: BudgetUpdatePayload }
    >({
      invalidatesTags: [
        "PurchaseOrders",
        "PurchaseOrdersDetails",
        "LeadStatusLogs",
        "LeadResume",
        "LeadDetails",
      ],
      query: (data) => ({
        url: `${API_HOST}/suppliers/v102/purchase-order/${data.id}`,
        method: "PUT",
        body: data.payload,
      }),
    }),

    updatePurchaseOrderDetail: builder.mutation<
      PurchaseOrder,
      BudgetDetailPayload
    >({
      invalidatesTags: ["PurchaseOrders", "PurchaseOrdersDetails"],
      query: (payload) => {
        const transformed = {
          ...payload,
          discount_tax: ((payload.discount_tax ?? 0) * 0.01).toFixed(4),
        };
        return {
          url: `${API_HOST}/suppliers/v102/purchase-order/details/${transformed.id}`,
          method: "PUT",
          body: transformed,
        };
      },
    }),

    deletePurchaseOrderDetails: builder.mutation<
      void,
      { purchaseOrderId: number | string; leadDetailId: number | string }
    >({
      invalidatesTags: ["PurchaseOrders", "PurchaseOrdersDetails"],
      query: ({ purchaseOrderId, leadDetailId }) => ({
        url: `${API_HOST}/suppliers/v102/purchase-order/${purchaseOrderId}/lead/${leadDetailId}`,
        method: "DELETE",
      }),
    }),

    getTechnicalIssues: builder.query<TechnicalIssue[], number | string>({
      query: (id) => `${API_HOST}/suppliers/v102/leads/${id}/technical-issues`,
      providesTags: ["LeadTechnicalIssues"],
    }),
    createTechnicalIssue: builder.mutation<
      TechnicalIssue,
      { id: number | string; payload: TechnicalIssue }
    >({
      invalidatesTags: ["LeadTechnicalIssues", "LeadStatusLogs", "LeadResume"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/leads/${id}/technical-issues`,
        method: "POST",
        body: payload,
      }),
    }),
    getTechnicalIssue: builder.query<TechnicalIssue, number>({
      query: (id) => `${API_HOST}/suppliers/v102/technical-issues/${id}`,
      providesTags: ["LeadTechnicalIssues"],
    }),
    removeTechnicalIssue: builder.mutation<void, number>({
      invalidatesTags: ["LeadTechnicalIssues", "LeadStatusLogs", "LeadResume"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/technical-issues/${id}/`,
        method: "DELETE",
      }),
    }),
    editTechnicalIssue: builder.mutation<TechnicalIssue, TechnicalIssue>({
      invalidatesTags: ["LeadTechnicalIssues", "LeadStatusLogs", "LeadResume"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/technical-issues/${payload.id}/`,
        method: "PUT",
        body: payload,
      }),
    }),
    saveTechnicalIssuesImages: builder.mutation<ProductImage[], ImagesPayload>({
      invalidatesTags: ["LeadTechnicalIssues", "LeadStatusLogs", "LeadResume"],
      query: ({ images, productId }) => ({
        url: `${API_HOST}/suppliers/v102/technical-issues/${productId}/images`,
        body: images,
        method: "POST",
      }),
    }),
    getBudgetsForCommercialControl: builder.query<
      SummaryCommercialControl,
      number | string
    >({
      query: (leadId) =>
        `${API_HOST}/suppliers/v102/commercial-control/${leadId}/budgets`,
      providesTags: ["Budgets"],
    }),
    getPOsForCommercialControl: builder.query<
      SummaryCommercialControl,
      number | string
    >({
      query: (leadId) =>
        `${API_HOST}/suppliers/v102/commercial-control/${leadId}/purchase-orders`,
      providesTags: ["PurchaseOrders"],
    }),
    createCommercialControlPayment: builder.mutation<
      PaymentsCommercialControl,
      { payload: FormData }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ payload }) => ({
        url: `${API_HOST}/suppliers/v102/payments`,
        method: "POST",
        body: payload,
      }),
    }),
    editCommercialControlPayment: builder.mutation<
      PaymentsCommercialControl,
      { id: number; payload: FormData }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/payments/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deleteCommercialControlPayment: builder.mutation<
      PaymentsCommercialControl,
      { id: number }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ id }) => ({
        url: `${API_HOST}/suppliers/v102/payments/${id}`,
        method: "DELETE",
      }),
    }),
    getSchedule: builder.query<ListPageResults<Budget>, GoPage | null>({
      providesTags: ["Schedule"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.page_size)
            params.append("page_size", goPage.page_size.toString());
          if (goPage.page_number)
            params.append("page_number", goPage.page_number.toString());
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);

          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else if (key === "status" && Array.isArray(goPage.filters[key])) {
                goPage.filters.status?.forEach((status: string) =>
                  params.append("status", `${status}`)
                );
              } else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/leads/schedule?${params.toString()}`;
      },
    }),
    sendDetailReminder: builder.mutation<any, number>({
      invalidatesTags: ["Schedule"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/lead-detail/${id}/mail`,
        method: "POST",
      }),
    }),
    getCommercialControlModule: builder.query<
      ListPageResults<SummaryCommercialControl>,
      { goPage: GoPage | null; kind: string }
    >({
      query: ({ goPage, kind }) => {
        const params = new URLSearchParams();

        if (goPage) {
          if (goPage.page_size)
            params.append("page_size", `${goPage?.page_size}`);
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.page_number)
            params.append("page_number", goPage.page_number.toString());
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);

          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else if (key === "status")
                goPage.filters.status?.forEach((status: string) =>
                  params.append("status", `${status}`)
                );
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/commercial-control/${kind}?${params.toString()}`;
      },
      providesTags: ["Budgets", "PurchaseOrders"],
    }),
    getSummaryForCommercialControlModule: builder.query<
      SummaryCommercialControlModule,
      { goPage: GoPage | null; kind: string }
    >({
      query: ({ goPage, kind }) => {
        const params = new URLSearchParams();

        if (goPage) {
          if (goPage.page_size)
            params.append("page_size", `${goPage?.page_size}`);
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);

          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else if (key === "status")
                goPage.filters.status?.forEach((status: string) =>
                  params.append("status", `${status}`)
                );
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/commercial-control/${kind}/summary?${params.toString()}`;
      },
      providesTags: ["Budgets", "PurchaseOrders"],
    }),
    createInvoicesForCommercialControl: builder.mutation<
      InvoiceCommercialControl,
      { payload: FormData; kind: string }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ payload, kind }) => ({
        url: `${API_HOST}/suppliers/v102/commercial-control/${kind}/invoices`,
        method: "POST",
        body: payload,
      }),
    }),
    editInvoiceCommercialControl: builder.mutation<
      PaymentsCommercialControl,
      { kind: string; id: number; payload: FormData }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ kind, id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/commercial-control/${kind}/invoices/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deleteInvoiceCommercialControl: builder.mutation<
      InvoiceCommercialControl,
      { id: number; kind: string }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ id, kind }) => ({
        url: `${API_HOST}/suppliers/v102/commercial-control/${kind}/invoices/${id}`,
        method: "DELETE",
      }),
    }),
    sendPaymentReminder: builder.mutation<
      any,
      { id: string | Number; emails?: string[] }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ id, emails }) => {
        return {
          url: `${API_HOST}/suppliers/v102/commercial-control/budgets/${id}/payment-reminder`,
          method: "POST",
          body: emails ? { emails: emails } : null,
        };
      },
    }),
    sendInvoice: builder.mutation<
      any,
      { id: number; kind: string; emails?: string[] }
    >({
      query: ({ kind, id, emails }) => {
        return {
          url: `${API_HOST}/suppliers/v102/commercial-control/${kind}/invoices/${id}/send`,
          method: "POST",
          body: emails ? { emails: emails } : null,
        };
      },
    }),
    sendInvoiceReminder: builder.mutation<
      any,
      { id: string | number; emails?: string[] }
    >({
      invalidatesTags: ["Budgets", "PurchaseOrders"],
      query: ({ id, emails }) => {
        return {
          url: `${API_HOST}/suppliers/v102/commercial-control/purchase-orders/${id}/payment-reminder`,
          method: "POST",
          body: emails ? { emails: emails } : null,
        };
      },
    }),

    getPayments: builder.query<
      ListPageResults<PaymentsCommercialControl>,
      GoPage | null
    >({
      providesTags: ["Payments"],
      query: (goPage) => {
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE.toString());
        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/payments?${params.toString()}`;
      },
    }),
    finishLeadDetail: builder.mutation<
      void,
      { uuid: string; is_finished: boolean }
    >({
      invalidatesTags: ["LeadDetails", "Schedule"],
      query: (data) => ({
        url: `${API_HOST}/suppliers/v102/lead-details/${data.uuid}`,
        method: "POST",
        body: {
          is_finished: data.is_finished,
        },
      }),
    }),
    getPurchaseOrderByLead: builder.query<
      PurchaseOrder[],
      { leadId: Number | string; goPage?: GoPage }
    >({
      keepUnusedDataFor: 0,
      providesTags: ["PurchaseOrders"],
      query: ({ leadId, goPage }) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.page_size)
            params.append("page_size", goPage.page_size.toString());
          if (goPage.page_number)
            params.append("page_number", goPage.page_number.toString());
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.status) params.append("status", goPage.status);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "lead_kind" && goPage.filters[key] === "sale")
                params.append("is_sale", "true");
              else if (key === "lead_kind" && goPage.filters[key] === "lease")
                params.append("is_lease", "true");
              else params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/leads/${leadId}/purchase-order?${params.toString()}`;
      },
    }),
    getCreditNotesLists: builder.query<CreditNote[], string>({
      query: (id) => `${API_HOST}/suppliers/v102/leads/${id}/credit-notes`,
      providesTags: ["LeadCreditNotesList"],
    }),
    getCreditNote: builder.query<
      CreditNote,
      { contentType: string; id: number | string }
    >({
      query: (payload) =>
        `${API_HOST}/suppliers/v102/credit-notes/${payload.contentType}/${payload.id}`,
      providesTags: ["LeadCreditNotesList"],
    }),
    createCreditNote: builder.mutation<
      CreditNote,
      { creditNote: CreditNote; lead_id: number | string }
    >({
      query: ({ creditNote, lead_id }) => {
        return {
          url: `${API_HOST}/suppliers/v102/leads/${lead_id}/credit-notes`,
          method: "POST",
          body: creditNote,
        };
      },
      invalidatesTags: ["LeadCreditNotesList", "Budgets", "PurchaseOrders"],
    }),
    creditNoteAttachment: builder.mutation<CreditNote, any>({
      query: (payload) => {
        return {
          url: `${API_HOST}/suppliers/v102/credit-notes/${payload.content_type}/${payload.id}/attachment`,
          method: "PATCH",
          body: payload.payload,
        };
      },
      invalidatesTags: ["LeadCreditNotesList", "Budgets", "PurchaseOrders"],
    }),
    editCreditNote: builder.mutation<CreditNote, CreditNote>({
      query: (payload) => {
        return {
          url: `${API_HOST}/suppliers/v102/credit-notes/${payload.content_type}/${payload.id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["LeadCreditNotesList", "Budgets", "PurchaseOrders"],
    }),
    deleteCreditNote: builder.mutation<CreditNote, CreditNote>({
      query: (payload) => {
        return {
          url: `${API_HOST}/suppliers/v102/credit-notes/${payload.content_type}/${payload.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["LeadCreditNotesList", "Budgets", "PurchaseOrders"],
    }),
    getCreditNotesSummary: builder.mutation<
      CreditNotesSummary,
      {
        lead_id: number | string;
        content_type: string;
        details: { details: CreditNoteDetail };
      }
    >({
      query: (payload) => {
        return {
          url: `${API_HOST}/suppliers/v102/leads/${payload.lead_id}/${payload.content_type}/credit-notes-summary`,
          method: "POST",
          body: payload.details,
        };
      },
    }),
    getOrganizationsLeadsCustomers: builder.query<[SimpleOrganization], GoPage>(
      {
        query: (goPage) => {
          const params = new URLSearchParams();
          if (goPage) {
            if (goPage.search) params.append("search", goPage.search);
            if (goPage.filters) {
              Object.keys(goPage.filters).forEach((key) => {
                params.append(key, goPage.filters[key]);
              });
            }
          }
          return `${API_HOST}/suppliers/v102/organizations?${params.toString()}`;
        },
      }
    ),
  }),
});

export const {
  useGetBudgetsQuery,
  useGetBinnacleQuery,
  usePostBinnacleCommentMutation,
  useGetLeadStatusesQuery,
  useGetLeadsQuery,
  useGetLeadsFilteredTemporalQuery,
  useGetLeadsByStatusForKanbanQuery,
  useGetLeadQuery,
  useGetRecommendationsQuery,
  useSaveLeadMutation,
  useDeleteLeadMutation,
  useUpdateLeadMutation,
  useCreateBudgetMutation,
  useCalculatePriceMutation,
  useGetBudgetV2Query,
  useCreateBudgetV2Mutation,
  useSendBudgetV2Mutation,
  useEditBudgetV2Mutation,
  useDeleteBudgetV2Mutation,
  useGetPurchaseOrderListV2Query,
  useGetPurchaseOrderV2Query,
  useCreatePurchaseOrderV2Mutation,
  useSendPurchaseOrderV2Mutation,
  useEditPruchaseOrderV2Mutation,
  useDeletePruchaseOrderV2Mutation,
  useUpdateStatusPurchaseOrderMutation,
  useDuplicateLeadMutation,
  useUpdateStatusLeadMutation,
  useCreateLeadToOrderMutation,
  useAssignResponsiblesMutation,
  useUpdateStatusBudgetMutation,
  useCreateBudgetToInvoiceMutation,
  useGetBudgetInvoicesQuery,
  useDeleteBudgetInvoiceMutation,
  useUpdateBudgetInvoiceStatusMutation,
  useUpdateBudgetInvoiceMutation,
  useGetPurchaseOrderInvoicesQuery,
  useDeletePurchaseOrderInvoiceMutation,
  useUpdatePurchaseOrderInvoiceMutation,
  useUpdatePurchaseOrderInvoiceStatusMutation,
  useCreatePurchaseOrderToInvoiceMutation,
  useValidateLeadRequiredDataMutation,
  useUpdateBinnacleCommentMutation,
  useCreateLeadAttachmentMutation,
  useGetLeadAttachmentsQuery,
  useGetBudgetsConditionsQuery,
  useCreateBudgetConditionMutation,
  useUpdateBudgetConditionMutation,
  useDeleteBudgetConditionMutation,
  useGetSupplierSearchForLeadDetailQuery,
  useCreateSupplierSearchForLeadDetailMutation,
  useCreateGeoBudgetConditionMutation,
  useUpdateGeoBudgetConditionMutation,
  useDeleteGeoBudgetConditionMutation,
  useGetGeoBudgetsConditionsQuery,
  useGetLeadResumeQuery,
  useGetLeadDetailsGroupedQuery,
  useCreateLeadDetailMutation,
  useUpdateLeadDetailMutation,
  useDeleteLeadDetailMutation,
  useGetBudgetsByLeadQuery,
  useGetNonPendingDocsQuery,
  useCreateBudgetByLeadMutation,
  useGetInternalOperationsQuery,
  useCreateBudgetDetailMutation,
  useGetBudgetDetailsQuery,
  useUpdateBudgetDetailMutation,
  useDeleteBudgetDetailsMutation,
  useUpdateBudgetMutation,
  useDeleteBudgetMutation,
  useDeleteBudgetDetailMutation,
  useGetLeadStatusLogsQuery,
  useGetMovementsListQuery,
  useGetMovementQuery,
  useCreateMovementMutation,
  useEditMovementMutation,
  useRemoveMovementMutation,
  useSaveMovementImagesMutation,
  useCreatePurchaseOrderDetailMutation,
  useGetPurchaseOrderDetailsQuery,
  useCreatePurchaseOrderByLeadMutation,
  useDeletePurchaseOrderDetailMutation,
  useDeletePurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
  useUpdatePurchaseOrderDetailMutation,
  useDeletePurchaseOrderDetailsMutation,
  useGetTechnicalIssuesQuery,
  useCreateTechnicalIssueMutation,
  useGetTechnicalIssueQuery,
  useRemoveTechnicalIssueMutation,
  useEditTechnicalIssueMutation,
  useSaveTechnicalIssuesImagesMutation,
  useGetBudgetsForCommercialControlQuery,
  useGetPOsForCommercialControlQuery,
  useCreateCommercialControlPaymentMutation,
  useEditCommercialControlPaymentMutation,
  useDeleteCommercialControlPaymentMutation,
  useGetScheduleQuery,
  useSendDetailReminderMutation,
  useGetCommercialControlModuleQuery,
  useGetSummaryForCommercialControlModuleQuery,
  useCreateInvoicesForCommercialControlMutation,
  useEditInvoiceCommercialControlMutation,
  useDeleteInvoiceCommercialControlMutation,
  useSendPaymentReminderMutation,
  useSendInvoiceMutation,
  useGetPaymentsQuery,
  useFinishLeadDetailMutation,
  useSendInvoiceReminderMutation,
  useGetLeadV2Query,
  useGetPurchaseOrderByLeadQuery,
  useGetCreditNotesListsQuery,
  useGetCreditNoteQuery,
  useCreateCreditNoteMutation,
  useCreditNoteAttachmentMutation,
  useEditCreditNoteMutation,
  useDeleteCreditNoteMutation,
  useGetCreditNotesSummaryMutation,
  useGetOrganizationsLeadsCustomersQuery,
  useGetLeadItemsQuery,
  useCreateLeadItemMutation,
  useUpdateLeadItemMutation,
  useDeleteLeadItemMutation,
  useCreateLeadItemPeriodMutation,
  useUpdateLeadItemPeriodMutation,
  useDeleteLeadItemPeriodMutation,
} = leadsApi;
